
.App-logo {

  max-width: 20rem ;
  pointer-events: none;
  align-self: center;
  margin: 1rem;
  margin-top: 4rem;
}
@media (prefers-reduced-motion: no-preference) {
  
}
