.App-footer {
    background-color:#000000;
    height: 1rem;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content:space-around;
    align-content: center;
    font-size:10px;
    color: #818181;
    padding: 5px;
    font-family: "Alloy-Regular";
    text-shadow:black 2px 2px 4px 
  }
  .span-footer {
    color: #f1f100;
  }