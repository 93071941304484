h1 {
    padding: 1px;
    margin: 1px;
    color: #f1f100;
    text-shadow: 2px 2px 4px #000000;
}

h2 {
    text-shadow: 2px 2px 4px #000000;
    margin: 1px;
    color: #bebebe;
}
h2:hover{
    color: #f1f100;
}
h3 {
    text-shadow: 2px 2px 4px #000000;
    margin: 1px;
    color: #dbd8d8
}

h5 {
    text-shadow: 2px 2px 4px #000000;
    margin: 5px; 
}

span {
    text-shadow: 2px 2px 4px #000000;
    margin: 1px;
    color: #b8aaaa
}

.card {
    border-radius: 10;
    background: linear-gradient(#09342D, #000000);
    color: rgb(243, 240, 240);
    padding: 2px;
    border-width: 3px;
    border-color: #494949;
    border-style: solid;
    border-radius: 10px;
    
}