.App-header {
    background-color: #09342D;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #f1f100;
    padding: 10px;
    font-family: "Alloy-Regular";
    position: center;
    
  }
  .Menu-title{
    font-family: "Alloy-Regular";
    font-weight: 100px;
    font-size: 55px;
    color: #F95A32;
    text-shadow:2px 2px 4px #000000;
  }
  .img1 {
    position: relative;
    
    top: 0;
    left: 0;
    border: 1px solid #000000;
  }
  
  .img2 {
    position: absolute;
    top: 20px;
    left: 20px;
    border: 1px solid #000000;
  }